import { RefObject, useRef } from "react";
import MpAboutUs from "./main-page-components/MpAboutUs";
import MpBlog from "./main-page-components/MpBlog";
import MpHowItWorks from "./main-page-components/MpHowItWorks";
import MpOurWork from "./main-page-components/MpOurWork";
import MpPageHome from "./main-page-components/MpPageHome";
import MpServices from "./main-page-components/MpServices";
import MpShowCase from "./main-page-components/MpShowCase";
import MpTestimonials from "./main-page-components/MpTestimonials";

function MainPage() {
  const servicesDivRef = useRef<HTMLDivElement>(null);
  const workDivRef = useRef<HTMLDivElement>(null);
  const aboutUsDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({ top: elementRef.current!.offsetTop - 40, behavior: "smooth" });
  };

  return (
    <div>
      <MpPageHome servicesDivRef={servicesDivRef} workDivRef={workDivRef} aboutUsDivRef={aboutUsDivRef} scrollHandler={scrollHandler} />

      <MpShowCase />

      <div id="about-us" ref={aboutUsDivRef}>
        <MpAboutUs />
      </div>

      <div id="services" ref={servicesDivRef}>
        <MpServices />
      </div>

      <div id="work" ref={workDivRef}>
        <MpOurWork />
      </div>

      <MpHowItWorks />

      <MpBlog />

      <MpTestimonials />

      <section id="fandq"></section>
    </div>
  );
}

export default MainPage;
