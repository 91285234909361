import { RefObject, useRef } from "react";
import GdAboutGraphic from "./graphic-designing-subcomponents/GdAboutGraphic";
import GdGraphicContact from "./graphic-designing-subcomponents/GdGraphicContact";
import GdGraphicProjects from "./graphic-designing-subcomponents/GdGraphicProjects";
import GdPageHome from "./graphic-designing-subcomponents/GdPageHome";

function GraphicDesigning() {
  const aboutGraphicDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ backgroundColor: "rgb(184, 216, 226)" }}>
      <GdPageHome
        aboutGraphicDivRef={aboutGraphicDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="aboutGraphicDiv" ref={aboutGraphicDivRef}>
        <GdAboutGraphic />
      </div>

      <GdGraphicProjects />

      <GdGraphicContact />
    </div>
  );
}

export default GraphicDesigning;
