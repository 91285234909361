import { RefObject, useRef } from "react";
import AdAboutApp from "./app-development-subcomponents/AdAboutApp";
import AdAppProjects from "./app-development-subcomponents/AdAppProjects";
import AdPageHome from "./app-development-subcomponents/AdPageHome";

function AppDevelopment() {
  const aboutAppDivRef = useRef<HTMLDivElement>(null);
  const appProjectsDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <AdPageHome
        aboutAppDivRef={aboutAppDivRef}
        appProjectsDivRef={appProjectsDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="aboutAppDivRef" ref={aboutAppDivRef}>
        <AdAboutApp />
      </div>

      <div id="appProjectsDiv" ref={appProjectsDivRef}>
        <AdAppProjects />
      </div>
    </div>
  );
}

export default AppDevelopment;
