import { RefObject, useRef } from "react";
import WdAboutWeb from "./website-development-subcomponents/WdAboutWeb";
import WdWebHome from "./website-development-subcomponents/WdWebHome";
import WdWebProjects from "./website-development-subcomponents/WdWebProjects";

function WebsiteDevelopment() {
  const webServicesDivRef = useRef<HTMLDivElement>(null);
  const webProjectsDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <WdWebHome
        webServicesDivRef={webServicesDivRef}
        webProjectsDivRef={webProjectsDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="webServicesDiv" ref={webServicesDivRef}>
        <WdAboutWeb />
      </div>

      <div id="webProjectsDiv" ref={webProjectsDivRef}>
        <WdWebProjects />
      </div>
    </div>
  );
}

export default WebsiteDevelopment;
