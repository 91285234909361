import WorkPageHome from "./work-subcomponents/WorkPageHome";
import WebWork from "./work-subcomponents/WebWork";
import GraphicWork from "./work-subcomponents/GraphicWork";
import AppWork from "./work-subcomponents/AppWork";
import { RefObject, useRef } from "react";

function Work() {
  const webWorkDivRef = useRef<HTMLDivElement>(null);
  const graphicWorkDivRef = useRef<HTMLDivElement>(null);
  const appWorkDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <WorkPageHome
        webWorkDivRef={webWorkDivRef}
        graphicWorkDivRef={graphicWorkDivRef}
        // appWorkDivRef={appWorkDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="webWorkDiv" ref={webWorkDivRef}>
        <WebWork />
      </div>

      <div id="graphicWorkDiv" ref={graphicWorkDivRef}>
        <GraphicWork />
      </div>

      <div id="appWorkDiv" ref={appWorkDivRef}>
        <AppWork />
      </div>
    </div>
  );
}

export default Work;
