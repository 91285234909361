import { RefObject, useRef } from "react";
import PricingApp from "./pricing-subcomponents/PricingApp";
import PricingDigital from "./pricing-subcomponents/PricingDigital";
import PricingGraphic from "./pricing-subcomponents/PricingGraphic";
import PricingPageHome from "./pricing-subcomponents/PricingPageHome";
import PricingWeb from "./pricing-subcomponents/PricingWeb";

function Pricing() {
  const webPricingDivRef = useRef<HTMLDivElement>(null);
  const digitalPricingDivRef = useRef<HTMLDivElement>(null);
  const graphicPricingDivRef = useRef<HTMLDivElement>(null);
  const appPricingDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <PricingPageHome
        webPricingDivRef={webPricingDivRef}
        digitalPricingDivRef={digitalPricingDivRef}
        graphicPricingDivRef={graphicPricingDivRef}
        appPricingDivRef={appPricingDivRef}
        scrollHandler={scrollHandler}
      />

      <div id="webPricingDiv" ref={webPricingDivRef}>
        <PricingWeb />
      </div>

      <div id="digitalPricingDiv" ref={digitalPricingDivRef}>
        <PricingDigital />
      </div>

      <div id="graphicPricingDiv" ref={graphicPricingDivRef}>
        <PricingGraphic />
      </div>

      <div id="appPricingDiv" ref={appPricingDivRef}>
        <PricingApp />
      </div>
    </div>
  );
}

export default Pricing;
