import { useRef } from "react";

function ContactUs() {
  const formRef = useRef<HTMLFormElement>(null);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const scriptURL =
  //   "https://script.google.com/macros/s/AKfycbw2CAC9HCjlGMspIpeunfox00QUK8lFR-VqS1oJIQDr2FeESuliYNjVARnq2mUOTi5E/exec";

  // const handleSubmit = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);

  //   if (formRef.current) {
  //     const formData = new FormData(formRef.current);

  //     try {
  //       const response = await fetch(scriptURL, {
  //         method: "POST",
  //         body: formData,
  //       });
  //       alert("Thank you for connecting with us, we will contact you soon.");
  //       console.log("Success:", response);
  //       window.location.reload();
  //     } catch (error: any) {
  //       console.error("Error:", error.message);
  //       alert(
  //         "Sorry, some error has occurred. Please write us an email or try again later."
  //       );
  //       console.error("Error:", error.message);
  //       window.location.reload();
  //     } finally {
  //       setIsSubmitting(false);
  //     }
  //   }
  // };

  return (
    <section
      id="contact"
      style={{ backgroundImage: "linear-gradient(black, #070F2B)" }}
    >
      <div className="container pt-5 text-white pb-md-5">
        <h2 className="color-blue text-center">
          <strong>Contact Us - We'll Reach Out to You Soon!</strong>
        </h2>
        <p className="color-blue text-center py-2 mb-3">
          <strong>Fill in the Form Below and Expect Our Prompt Response</strong>
        </p>
        <div className="row my-3 justify-content-center align-items-center ">
          <div className="col-md-6 d-flex justify-content-center">
            <div className="card card-body border-0 bg-blue min-card">
              <form
                ref={formRef}
                id="form"
                //  onSubmit={handleSubmit}
                action="https://vyomscode.com/contactform.php"
                method="post"
              >
                <div className="row">
                  <div className="col-12 py-2">
                    <input
                      type="name"
                      name="name"
                      className="form-control bg-blue-light"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="email"
                      name="email"
                      className="form-control bg-blue-light"
                      placeholder="Your Email Address"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <input
                      type="number"
                      name="number"
                      className="form-control bg-blue-light"
                      placeholder="Your Mobile Number"
                      required
                    />
                  </div>
                  <div className="col-12 py-2">
                    <textarea
                      name="msg"
                      id=""
                      cols={35}
                      className="form-control bg-blue-light"
                      rows={5}
                      placeholder="How Can I help You"
                      required
                    ></textarea>
                  </div>
                  <div className="col-12 d-flex justify-content-cneter">
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-warning w-100"
                    >
                      Submit
                    </button>
                    {/* <button
                      id="submit"
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-warning w-100"
                    >
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
