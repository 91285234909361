import { Link } from "react-router-dom";
import OurWorkBgImg from "../../../assets/5.webp";
import OurWorkCard from "../reusable-components/OurWorkCard";
import { ourWorkData } from "../../../common/data";

function MpOurWork() {
  return (
    <section id="our-work">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h3 className="text-light">
              <b>Our Work</b>
            </h3>
            <p className="lh-lg text-white">
              Discover the essence of VyomsCode through our portfolio, where
              creativity meets functionality. Explore our diverse range of web
              development projects showcasing responsive designs and seamless
              user experiences , innovative app creations designed to elevate
              user engagement and streamline operations , captivating graphic
              designs, from stunning logos to compelling branding assets.
              {/* <!-- With VyomsCode, witness the fusion of artistry and technology in every project we undertake. --> */}
            </p>
            <div className="row">
              {ourWorkData.map((item) => {
                return (
                  <OurWorkCard
                    key={item.id}
                    workImg={item.workImg}
                    title={item.title}
                    goToLink={item.goToLink}
                  />
                );
              })}
            </div>
            <Link to="work">
              <button className="btn btn-warning my-3 w-25">Explore</button>
            </Link>
          </div>

          <div className="col-md-5 my-3">
            <img
              src={OurWorkBgImg}
              className="w-100 rounded"
              alt="our work background"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MpOurWork;
