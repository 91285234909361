import { RefObject, useRef } from "react";
import GoToTopButton from "../../common-components/GoToTopButton";
import DmAboutDigital from "./digital-marketing-subcomponents/DmAboutDigital";
import DmPageHome from "./digital-marketing-subcomponents/DmPageHome";

function DigitalMarketing() {
  const aboutDigitalDivRef = useRef<HTMLDivElement>(null);

  const scrollHandler = (elementRef: RefObject<HTMLDivElement>) => {
    window.scrollTo({
      top: elementRef.current!.offsetTop - 40,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <DmPageHome aboutDigitalDivRef={aboutDigitalDivRef} scrollHandler={scrollHandler} />

      <div id="aboutDigitalDiv" ref={aboutDigitalDivRef}>
        <DmAboutDigital />
      </div>
    </div>
  );
}

export default DigitalMarketing;
